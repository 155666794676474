<template>
  <div class="manager-member-page">
    <div class="search-wrapper">
      <el-button
        size="small"
        icon="el-icon-plus"
        class="add-btn"
        @click="goto('')"
        >新增类型</el-button
      >
    </div>

    <div class="table-wrapper">
      <div class="box-table">
        <el-table
          v-loading="pageLoading"
          :data="pageData"
          style="width: 100%"
          height="100%"
          @selection-change="selectionChange"
        >
          <el-table-column prop="name" label="类型名称" align="center">
          </el-table-column>
          <el-table-column
            prop="similarityThreshold"
            label="阈值"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="260px">
            <template slot-scope="scope">
              <div class="opt-wrapper">
                <div
                  class="opt-icon"
                  title="详情"
                  @click="gotoDetail(scope.row)"
                >
                  <!-- <i class="iconfont icon-xiangqing1"></i> -->

                  <img
                    src="../../../assets/images/icon/icon_xiangqing.png"
                    alt=""
                  />
                </div>
                <div class="opt-icon" title="编辑" @click="goto(scope.row)">
                  <!-- <i class="iconfont icon-bianji1"></i> -->

                  <img
                    src="../../../assets/images/icon/icon_bianji.png"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageParams.total"
          :current-page="pageParams.currentPage"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogFormShow"
      :close-on-click-modal="false"
      custom-class="form-dialog"
      :before-close="handleFormClose"
      :title="title"
      width="40%"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="xForm"
        class="x-form"
        label-position="right"
        label-width="120px"
      >
        <el-form-item prop="name" label="类型名称">
          <el-input
            v-model="form.name"
            placeholder="类型名称"
            :disabled="disabled"
            show-word-limit
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item prop="similarity" label="参考阈值">
          <el-input-number
            style="width: 100%"
            v-model="form.similarityThreshold"
            controls-position="right"
            :min="0"
            :max="1"
            :step="0.1"
            :precision="2"
          ></el-input-number>
          <!-- <el-input
            v-model="form.similarityThreshold"
            type='number'
            placeholder="阈值范围"
          ></el-input> -->
        </el-form-item>
        <el-form-item prop="label" label="标签名称">
          <el-input
            :disabled="disabled"
            type="textarea"
            v-model="form.label"
            placeholder="以逗号分隔"
            :rows="5"
            show-word-limit
            maxlength="255"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn-group">
          <el-button plain class="s-btn" @click="onSubmit">确定</el-button>
          <el-button class="c-btn" @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogDetailShow"
      :close-on-click-modal="false"
      custom-class="form-dialog"
      title="详情"
      width="40%"
    >
      <el-descriptions class="margin-top" :column="1" size="small">
        <el-descriptions-item label="类型名称">{{
          form.name
        }}</el-descriptions-item>
        <el-descriptions-item label="参考阈值">{{
          form.similarityThreshold
        }}</el-descriptions-item>
        <el-descriptions-item label="标签名称">{{
          form.label
        }}</el-descriptions-item>
      </el-descriptions>
      <div class="close-btn">
        <el-button @click="dialogDetailShow = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      title: "",
      pageParams: {
        total: 0,
        currentPage: 1,
        pageNum: 1,
        pageSize: 10,
      },
      searchForm: {
        search: "",
      },
      pageData: [],
      pageLoading: false,
      dialogFormShow: false,
      dialogDetailShow: false,
      defaultFormData: {
        name: "",
        value: "",
        label: "",
        comTagInfos: [],
      },
      form: {},
      rules: {
        name: [{ required: true, message: "请填写类型名称", trigger: "blur" }],
        value: [{ required: true, message: "请填写参考阈值", trigger: "blur" }],
        label: [{ required: true, message: "请填写标签名称", trigger: "blur" }],
      },
      detailInfo: {},
      disabled: false,
    };
  },
  created() {
    this.getPageData(true);
  },
  methods: {
    goto(data) {
      this.dialogFormShow = true;
      this.form = Object.assign({}, this.defaultFormData);
      if (data) {
        this.getTagDetail(data.id);
        this.title = "编辑";
        this.disabled = true;
      } else {
        this.title = "新增";
        this.disabled = false;
      }
    },
    gotoDetail(data) {
      this.dialogDetailShow = true;
      // this.detailInfo = Object.assign({}, data);
      this.getTagDetail(data.id);
    },
    // 清空搜索
    handleClear() {
      this.searchForm.search = "";
      this.$refs.searchForm.resetFields();
      this.getPageData(true);
    },
    selectionChange(data) {
      let idList = [];
      if (data.length) {
        data.forEach((item) => {
          idList.push(item.id);
        });
        this.idList = idList;
      }
    },
    // 获取设计师列表
    getPageData(flag) {
      if (flag) {
        this.pageData = [];
        this.pageParams = {
          pageNum: 1,
          pageSize: 10,
          total: 0,
          currentPage: 0,
        };
      }
      let params = Object.assign({}, this.pageParams);
      this.pageLoading = true;
      this.$http
        .get("/com-tag-category/listPage", { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (res.data && res.data.records) {
              this.pageData = res.data.records;
              this.pageParams.total = res.data.total;
              this.pageParams.currentPage = res.data.current;
            }
          } else {
            this.$message.error("获取数据失败" + res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
          this.$message.error("获取数据失败," + err);
        });
    },
    // 获取标签分类详情
    getTagDetail(id) {
      this.$http.get("/com-tag-category/" + id).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.form = res.data;
          let comTagInfos = res.data.comTagInfos;
          this.form.label = comTagInfos
            ? comTagInfos.map((item) => item.name).join()
            : "";
        } else {
          this.form = {};
        }
      });
    },
    // 分页
    handlePageChange(current) {
      this.pageParams.currentPage = current;
      this.pageParams.pageNum = current;
      this.getPageData();
    },
    handleFormClose(done) {
      this.$refs.xForm.resetFields();
      done();
    },
    onSubmit() {
      this.$refs.xForm.validate((valid) => {
        if (valid) {
          if (this.form.id) {
            this.handleUplate();
          } else {
            this.handleInsert();
          }
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.$refs.xForm.resetFields();
      this.dialogFormShow = false;
    },
    // 新增
    handleInsert() {
      let label = this.form.label.replace(/，/gi, ",");
      let tags = label.split(",");
      let comTagInfos = [];
      tags.forEach((item) => {
        if (item.trim()) {
          comTagInfos.push({ name: item.trim() });
        }
      });
      let params = {
        comTagInfos,
        name: this.form.name,
        similarityThreshold: this.form.similarityThreshold,
      };
      console.log(params);
      this.$http
        .post("/com-tag-category/save", params)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.dialogFormShow = false;
            this.getPageData(true);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 编辑
    handleUplate() {
      let params = {
        id: this.form.id,
        // comTagInfos: this.form.comTagInfos,
        // name: this.form.name,
        similarityThreshold: this.form.similarityThreshold,
      };
      console.log(params);
      this.$http
        .post("/com-tag-category/update", params)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.dialogFormShow = false;
            this.getPageData(true);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style lang="less">
.el-descriptions {
  width: 80%;
  margin: 0 auto;
}
.el-descriptions-item__label {
  width: 80px;
  font-size: 16px;
  color: #8c8c8c;
}
.el-descriptions-item__content {
  color: #3c3c3c;
  font-size: 16px;
  width: calc(100% - 80px);
}
.close-btn {
  padding: 20px;
  text-align: center;
  .el-button {
    &:hover {
      border: 1px solid #8565f6;
      background-color: #fff;
      color: #8565f6;
    }
  }
}
</style>
